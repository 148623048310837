import React, { useRef } from 'react';
import IdleTimer from 'react-idle-timer';

//sessionTimeoutPeriod is in minutes
const AutoLogout = ({ sessionTimeoutPeriod = 15, onIdle }) => {
  const idleTimerRef = useRef(null);
  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        timeout={1000 * 60 * sessionTimeoutPeriod}
        onIdle={onIdle}></IdleTimer>
    </div>
  );
};

export default AutoLogout;
