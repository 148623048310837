import React, { Component, Suspense, useState, useEffect } from 'react';

//Router Dom
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

//Redux
import { connect } from 'react-redux';

//API Calls

//Common Components
import NotFound from '../components/common/NotFound';
import JDProcessOverlay from '../components/common/JDProcessOverlay';
import XPAErrorBoundary from '../containers/common/XPAErrorBoundary';
import { FlaskLoader } from '../components/common/common';

//Main Functional Components
import ScheduleMaintenance from '../components/public/ScheduleMaintenance';

//Main Components (UI)
import MainApp from './MainApp';
import CandidateTestSummary from '../containers/common/public/CandidateTestSummary';
import CandidateSoftskillAssessment from '../containers/common/public/CandidateSoftskillAssessment';
import PublicHeader from '../headers/PublicHeader';
import ScheduleMaintenanceHeader from '../headers/ScheduleMaintenanceHeader';
import Login from '../containers/common/public/Login';
import CandPAEScenario from '../containers/common/public/CandPAEScenario';
import CandidateFeedback from '../containers/common/public/CandidateFeedback';
import PartnerDashboard from '../containers/common/public/PartnerDashboard';
import SGDSMastfooter from '../footers/SGDSMastfooter';
import PscPeerFeedbackHomePage from '../containers/common/public/PscPeerFeedbackHomePage';
import PscPeerFeedbackAssessList from '../containers/common/public/PscPeerFeedbackAssessList';
import PscPeerFeedbackAssessment from '../containers/common/public/PscPeerFeedbackAssessment';
import AutoLogout from '../containers/common/public/AutoLogout';

const Routes = (props) => {
  const {
    user,
    history,
    isUserLoggedIn,
    match: { url, params },
  } = props;
  const { push, location } = history || {};
  const { userId, roleId } = user || {};
  const showScheduledMaintainanceHeader = false;
  const isScheduledMaintainance = false;

  const [stateObj, setStateObj] = useState({
    showSubItems: false,
    isLogging: false,
    isSideNavExpanded: false,
    isOpenNavModal: false,
  });

  const isAuthenticated = sessionStorage.getItem('auth') === 'true'; // Check login state
  const requiresAuth = location.pathname.includes('psc-peer-feedback'); // Apply auth only here

  const {
    showSubItems,
    isLogging,
    isSideNavExpanded,
    subItems,
    label,
    description,
    isOpenNavModal,
  } = stateObj;

  useEffect(() => {}, []);

  const onIdle = () => {
    const { id } = params || {};
    sessionStorage.removeItem('auth'); // Remove session
    push(`/psc-peer-feedback/${id}`); // Redirect to login
  };

  // const setLogging = (isLogging) => {
  //   setStateObj({ ...stateObj, isLogging });
  // };

  // const handleShowSubMenu = (subItems, label, description, value) => {
  //   const stateSubItems = stateObj?.subItems;
  //   const stateLabel = stateObj?.label;
  //   if (value === true) {
  //     if (
  //       stateSubItems &&
  //       Array.isArray(stateSubItems) &&
  //       stateSubItems.length > 0 &&
  //       label !== stateLabel
  //     ) {
  //       setStateObj({
  //         ...stateObj,
  //         showSubItems: true,
  //         subItems,
  //         label,
  //         description,
  //       });
  //     } else {
  //       setStateObj({
  //         ...stateObj,
  //         showSubItems: !showSubItems,
  //         subItems,
  //         label,
  //         description,
  //       });
  //     }
  //   } else {
  //     setStateObj({
  //       ...stateObj,
  //       showSubItems: false,
  //       subItems: [],
  //       label: '',
  //       description: '',
  //     });
  //   }
  // };

  // const onClickSideNavExpand = (hideSideNav) => {
  //   setStateObj({
  //     ...stateObj,
  //     isSideNavExpanded: hideSideNav === true ? false : !isSideNavExpanded,
  //   });
  // };

  // const handleOpenNavigation = (openNavModal) => {
  //   setStateObj({
  //     ...stateObj,
  //     isOpenNavModal: openNavModal,
  //   });
  // };

  return (
    <div>
      <div
        className={`${
          showScheduledMaintainanceHeader ? 'css-headre-top' : ''
        }`}>
        {showScheduledMaintainanceHeader && <ScheduleMaintenanceHeader />}
      </div>
      <PublicHeader push={push} />
      <div className="cds--grid mb-5 css-main-body">
        {isScheduledMaintainance ? (
          <ScheduleMaintenance user={user} history={history} />
        ) : (
          <div>
            {requiresAuth && (
              <AutoLogout sessionTimeoutPeriod={60} onIdle={onIdle} />
            )}
            {console.log(
              '$$',
              requiresAuth,
              isAuthenticated,
              requiresAuth && !isAuthenticated
            )}
            <Suspense fallback={<FlaskLoader />}>
              <Switch>
                {requiresAuth && !isAuthenticated ? (
                  <>
                    <Route
                      path={`/psc-peer-feedback/:id`}
                      render={(routeProps) => (
                        <Login {...routeProps} isPscPeerFeedbackLogin={true} />
                      )}
                    />
                  </>
                ) : (
                  <>
                    <Route
                      exact
                      path={`/public/registration/:accessCode`}
                      render={(routeProps) => (
                        <Login {...routeProps} isCandidateLogin={true} />
                      )}
                    />
                    <Route
                      exact
                      path={`/partner/login`}
                      render={(routeProps) => (
                        <Login {...routeProps} isPartnerLogin={true} />
                      )}
                    />

                    <Route
                      exact
                      path={`/partner/dashboard`}
                      component={PartnerDashboard}
                    />
                    <Route
                      exact
                      path={`/public/registration/test/:scenarioHash`}
                      render={() => (
                        <Redirect
                          to={{
                            pathname: '/public/invite/cand',
                            state: { xfrom: location },
                          }}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={`/public/invite/cand`}
                      component={CandPAEScenario}
                    />
                    <Route
                      exact
                      path={`/public/test-summary`}
                      component={CandidateTestSummary}
                    />
                    <Route
                      exact
                      path={`/public/c/assessment/:id`}
                      component={CandidateSoftskillAssessment}
                    />
                    <Route
                      exact
                      path={`/public/c/feedback/overall/:id`}
                      component={CandidateFeedback}
                    />
                    <Route
                      exact
                      path={`/psc-peer-feedback/:id`}
                      component={PscPeerFeedbackHomePage}
                    />
                    <Route
                      exact
                      path={`/psc-peer-feedback/:id/assessments`}
                      component={PscPeerFeedbackAssessList}
                    />
                    <Route
                      exact
                      path={`/psc-peer-feedback/:id/assessment/:candidateId/:sectionKey`}
                      component={PscPeerFeedbackAssessment}
                    />
                  </>
                )}
                <Route path="/*" component={NotFound} />
              </Switch>
              <JDProcessOverlay show={isLogging} message="processing..." />
            </Suspense>
          </div>
        )}
      </div>
      <SGDSMastfooter />
    </div>
  );
};

const mapStateToProps = (state) => ({
  // isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  // user: state.x0paDuser.user,
});

const mapDispatchToProps = {};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
