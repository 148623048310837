import callApi from './callApi';
import { baseUrl } from '../utils/config';
import {
  REQUEST_GET_PSC_ASSESSMENT_INFO_BY_UUID,
  REQUEST_UPSERT_INDV_SECTION_RESPONSES,
  REQUEST_SUBMIT_ENTIRE_ASSESSMENT,
  REQUEST_SUBMIT_RETAKE_REQUEST,
} from './actionTypes';

const getAsessmentInfoByUUID = (publicUuid) => (dispatch) => {
  if (!publicUuid) {
    const error = {
      msg: 'Invalid id passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/psc-peer-feedback-assessment/${publicUuid}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_PSC_ASSESSMENT_INFO_BY_UUID,
  };
  return callApi(params);
};

const upsertIndvAssSectionResponses = (data) => (dispatch) => {
  const url = `${baseUrl}/psc-peer-feedback-assessment/responses?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPSERT_INDV_SECTION_RESPONSES,
    url,
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
  };
  return callApi(params);
};

const submitEntireAssessment = (data) => (dispatch) => {
  const url = `${baseUrl}/psc-peer-feedback-assessment/final`;
  const params = {
    dispatch,
    actionType: REQUEST_SUBMIT_ENTIRE_ASSESSMENT,
    url,
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
  };
  return callApi(params);
};

const submitRetakeRequest = (data) => (dispatch) => {
  const url = `${baseUrl}/psc-peer-feedback-survey/retake/request`;
  const params = {
    dispatch,
    actionType: REQUEST_SUBMIT_RETAKE_REQUEST,
    url,
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
  };
  return callApi(params);
};

export {
  getAsessmentInfoByUUID,
  upsertIndvAssSectionResponses,
  submitEntireAssessment,
  submitRetakeRequest,
};
