import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button,
  InlineNotification,
  RadioButtonGroup,
  RadioButton,
  UnorderedList,
  ListItem,
} from '@carbon/react';
import {
  getAsessmentInfoByUUID,
  generateAssessmentOTP,
  getAssessmentInfoByOTP,
  saveCandResponseByIndvQues,
  submitCandAssessmentResponse,
} from '../../../actions/Assessments';
import PublicHeader from '../../../headers/PublicHeader';
import CscImage from '../../../assets/images/x0pa-brown.png';
import JDProcessOverlay from '../../../components/common/JDProcessOverlay';
import _ from 'lodash';
import './CandidateSoftskillAssessment.css';

const serverBusyMessage =
  'Unable to process the request, please try again. If the issue persists, please contact cscollege_ars@cscollege.gov.sg';

const renderHtml = (htmlObject) => {
  return <div dangerouslySetInnerHTML={htmlObject} />;
};

class CandidateSoftskillAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startAssessment: false,
      currPage: 1,
      pageLimit: 20,
      isAtBottom: false,
    };
    this.scrollHandler = null;
  }

  componentDidMount() {
    this.handleGetAssessmentInfo();
    this.scrollHandler = this.debounce(this.checkScrollPosition, 100);
    window.addEventListener('scroll', this.scrollHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandler);
  }

  debounce = (func, delay) => {
    let timeout;
    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(), delay);
    };
  };

  checkScrollPosition = () => {
    const buffer = 90; // Buffer in pixels
    const scrollPosition = window.scrollY + window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight - 80;

    const isAtBottom = Math.abs(scrollPosition - documentHeight) <= buffer;

    if (this.state.isAtBottom !== isAtBottom) {
      this.setState({ isAtBottom });
    }
  };

  handleNotification = (nshow, nkind, nmessage) => {
    this.setState({
      notifShow: nshow,
      notifKind: nkind,
      notifMessage: nmessage,
    });
    setTimeout(() => {
      this.setState({
        notifShow: false,
        notifKind: nkind,
        notifMessage: nmessage,
      });
    }, 5000);
  };

  handleErrorNotification = (err) => {
    console.error(err);
    window.scrollTo(0, 0);
    const { error, message } = err || {};
    let errorMessage = message;
    if (!errorMessage && error) {
      const { message } = error || {};
      errorMessage = message;
    }
    if (errorMessage) {
      errorMessage = `${errorMessage} Please try again. If issue persists then contact us at cscollege_ars@cscollege.gov.sg`;
    }
    const defaultMessage =
      'Unknown error occured. Please try again. If issue persists then contact us at cscollege_ars@cscollege.gov.sg';

    this.handleNotification(true, 'error', errorMessage || defaultMessage);
  };

  getSoftskillRespObj = (quesIdxToBeResumed, Softskillquestions) => {
    const respObj = {};
    if (
      Softskillquestions &&
      Array.isArray(Softskillquestions) &&
      Softskillquestions.length > 0
    ) {
      Softskillquestions.forEach((ques, idx) => {
        const { response } = ques || {};
        const { questionId, value } = response || {};
        if (idx < quesIdxToBeResumed) {
          respObj[`softskill-${questionId}`] = value;
        }
      });
    }
    return respObj;
  };

  handleGetAssessmentInfo = () => {
    const { match: { params: { id } = {} } = {} } = this.props;
    const { pageLimit } = this.state;
    this.props
      .getAsessmentInfoByUUID(id)
      .then((res) => {
        if (res && !res.error) {
          const {
            assessmentId,
            Softskillquestions,
            Candidate,
            Softskill,
            status,
            softskillAssessmentId,
          } = res || {};
          const { firstName, lastName, fullName, email } = Candidate || {};
          const { optionsConfig } = Softskill || {};
          const quesIdxToBeResumed =
            (Softskillquestions &&
              Array.isArray(Softskillquestions) &&
              Softskillquestions.findIndex(
                (element) => element?.response == null
              )) ||
            0;

          const currPageLimit = quesIdxToBeResumed / pageLimit;
          const currPage =
            currPageLimit % 1 == 0
              ? currPageLimit + 1
              : Math.ceil(currPageLimit);

          const updateStateWithResponses = this.getSoftskillRespObj(
            quesIdxToBeResumed,
            Softskillquestions
          );

          this.setState({
            assessmentId,
            firstName,
            lastName,
            fullName,
            email,
            questions: Softskillquestions,
            questionConfigOptions: optionsConfig,
            assessStatus: status,
            softskillAssessmentId,
            currPage,
            ...updateStateWithResponses,
          });
        } else {
          this.handleErrorNotification();
        }
      })
      .catch((err) => {
        console.error(err);
        this.handleErrorNotification(err);
      });
  };

  handleGetAssessOTP = () => {
    const { match: { params: { id } = {} } = {} } = this.props;
    this.props
      .generateAssessmentOTP(id)
      .then((res) => {
        const { message, error } = (res && res[id]) || {};
        const { message: errMsg } = error || {};
        if (!res) {
          throw Error(serverBusyMessage);
        }
        if (message === 'Otp email sent') {
          const emailSentMessage = 'OTP has been sent successfully';
          this.handleNotification(true, 'success', emailSentMessage);
        } else if (errMsg) {
          this.handleNotification(true, 'error', errMsg);
        }
      })
      .catch((err) => {
        this.handleErrorNotification(err);
      });
  };

  // handleValidateAssessOTP = () => {
  //   const { match: { params: { id } = {} } = {} } = this.props;
  //   const { otp } = this.state;
  //   const data = {
  //     publicUuid: id,
  //     enteredOtp: (otp && otp.trim()) || '',
  //   };
  //   this.props
  //     .getAssessmentInfoByOTP(data)
  //     .then((res) => {
  //       const {
  //         error,
  //         softskillAssessmentId,
  //         Softskill,
  //         Softskillquestions,
  //         status,
  //       } = res || {};
  //       const { optionsConfig } = Softskill || {};
  //       if (error) {
  //         throw error;
  //       }
  //       if (!res) {
  //         throw new Error(serverBusyMessage);
  //       }
  //       if (
  //         !error &&
  //         Softskillquestions &&
  //         Array.isArray(Softskillquestions) &&
  //         Softskillquestions.length > 0
  //       ) {
  //         const quesIdxToBeResumed = Softskillquestions.findIndex(
  //           (element) => element?.response == null
  //         );
  //         this.setState({
  //           isOTPValidated: true,
  //           questions: Softskillquestions,
  //           softSkillsInfo: res,
  //           questionConfigOptions: optionsConfig,
  //           assessStatus: status,
  //           softskillAssessmentId,
  //           currQuesNumber: quesIdxToBeResumed || 0,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       this.handleErrorNotification(err);
  //     });
  // };

  handleChange = (e) => {
    const { name, value } = (e && e.target) || {};
    this.setState({
      [name]: value,
    });
  };

  handleOnChangeRadioBtn = (value, questionId) => {
    this.setState({
      [`softskill-${questionId}`]: value,
      [`softskill-${questionId}-isInvalid`]: false,
    });
  };

  handleSubmitResponses = (
    startingQuesIdx,
    endingQuesIdx,
    questions,
    markAssessDone
  ) => {
    const { softskillAssessmentId, currPage, pageLimit } = this.state;
    const data = [];
    if (questions && Array.isArray(questions) && questions.length > 0) {
      questions.forEach((ques, idx) => {
        const { questionId, orderNo } = ques || {};
        if (idx >= startingQuesIdx && idx < endingQuesIdx) {
          const resp = this.state[`softskill-${questionId}`];
          data.push({
            softskillAssessmentId,
            questionId,
            optionId: resp,
            value: resp,
            questionOrderNo: orderNo,
          });
        }
      });
    }

    this.props
      .saveCandResponseByIndvQues(data)
      .then((res) => {
        const { error, validRequests, invalidRequests } = res || {};
        if (error) {
          throw error;
        }
        if (!res) {
          throw new Error(serverBusyMessage);
        }

        if (!_.isEmpty(invalidRequests)) {
          this.handleErrorNotification();
        }

        if (
          !error &&
          _.isEmpty(invalidRequests) &&
          (Object.keys(validRequests)?.length == pageLimit || markAssessDone)
        ) {
          if (markAssessDone) {
            this.markAssessDone();
          } else {
            window.scrollTo(0, 0);
            this.setState({
              currPage: currPage + 1,
            });
          }
        }
      })
      .catch((err) => {
        this.handleErrorNotification(err);
      });
  };

  markAssessDone = () => {
    const { softskillAssessmentId } = this.state;
    const data = {
      softskillAssessmentId,
      isAssessmentDone: true,
    };

    this.props
      .submitCandAssessmentResponse(data)
      .then((res) => {
        const { error, isUpdated } = res || {};
        if (error) {
          throw error;
        }
        if (!res) {
          throw new Error(serverBusyMessage);
        }
        if (!error && isUpdated) {
          this.setState({ showCongrats: true });
        }
      })
      .catch((err) => {
        this.handleErrorNotification(err);
      });
  };

  validateResponses = (markAssessDone) => {
    const { currPage, pageLimit, questions } = this.state;
    const startingQuesIdx = currPage * pageLimit - pageLimit;
    const endingQuesIdx = currPage * pageLimit;
    if (questions && Array.isArray(questions) && questions.length > 0) {
      const updateState = {};
      questions.forEach((ques, idx) => {
        const { questionId } = ques || {};
        if (idx >= startingQuesIdx && idx < endingQuesIdx) {
          const resp = this.state[`softskill-${questionId}`];
          updateState[`softskill-${questionId}-isInvalid`] = !!!resp;
        }
      });
      const hasInvalidResponses = Object.values(updateState).some(
        (x) => x == true
      );
      this.setState({ ...updateState, hasInvalidResponses });
      if (!hasInvalidResponses) {
        this.handleSubmitResponses(
          startingQuesIdx,
          endingQuesIdx,
          questions,
          markAssessDone
        );
      }
    }
  };

  handleNextPage = (markAssessDone) => {
    this.validateResponses(markAssessDone);
  };

  handlePreviousPage = () => {
    const { match: { params: { id } = {} } = {} } = this.props;
    this.props
      .getAsessmentInfoByUUID(id)
      .then((res) => {
        if (res && !res.error) {
          const { Softskillquestions } = res || {};
          window.scrollTo(0, 0);
          this.setState({
            questions: Softskillquestions,
            currPage: this.state.currPage - 1,
          });
        } else {
          this.handleErrorNotification();
        }
      })
      .catch((err) => {
        console.error(err);
        this.handleErrorNotification(err);
      });
  };

  render() {
    const { history } = this.props;
    const { push } = history || {};
    const {
      notifShow,
      notifKind,
      notifMessage,
      firstName = '',
      lastName = '',
      fullName = '',
      email,
      assessStatus,
      questions,
      questionConfigOptions,
      startAssessment,
      showCongrats,
      currPage,
      pageLimit,
      hasInvalidResponses,
    } = this.state;

    const isThisAssessCompletedByUser = assessStatus === 'Completed';
    const showSubmitBtn = questions?.length <= currPage * pageLimit;

    return (
      <div>
        {notifShow && (
          <InlineNotification
            lowContrast
            hideCloseButton
            className="m-0 p-0 w-100"
            kind={notifKind}
            title={notifMessage}
            onCloseButtonClick={() => {
              this.setState({ notifShow: false });
            }}
          />
        )}
        {isThisAssessCompletedByUser ? (
          <div className="cds--row css-web-height w-100 m-0">
            <div className="cds--col-lg-8 cds--col-md-16 cds--col-sm-16 cds--col-xs-16 left-bg-color d-flex flex-column justify-content-center align-items-center">
              <div>
                <img style={{ width: 200 }} src={CscImage} alt="CSC Image" />
              </div>
            </div>
            <div className="cds--col-lg-8 cds--col-md-16 cds--col-sm-16 cds--col-xs-16 d-flex flex-column justify-content-center align-items-center">
              <div className="">
                <div className="h3 font-weight-bold">
                  Thank you! You've already submitted the personality test.
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {!startAssessment ? (
              <div className="cds--row css-web-height w-100 m-0">
                <div className="cds--col-lg-8 cds--col-md-16 cds--col-sm-16 cds--col-xs-16 left-bg-color d-flex flex-column justify-content-center align-items-center">
                  <div>
                    <img
                      style={{ width: 200 }}
                      src={CscImage}
                      alt="CSC Image"
                    />
                  </div>
                </div>
                <div className="cds--col-lg-8 cds--col-md-16 cds--col-sm-16 cds--col-xs-16 d-flex flex-column justify-content-center align-items-center ml-4 ml-lg-0">
                  <div className="">
                    <div className="mt-2 mb-3 font-weight-bold h6">
                      Hi{' '}
                      <span className="text-capitalize cds--type-bold">
                        {fullName},
                      </span>
                    </div>
                    <h5 className="mb-2">
                      <u>
                        Please read these instructions carefully before
                        completing the questionnaire.
                      </u>
                    </h5>
                    <div className="mb-2 cds--type-bold">
                      This questionnaire contains 240 statements. Read each
                      statement carefully and choose the answer that best
                      corresponds to your view:
                    </div>
                    <div className="ml-4">
                      <UnorderedList>
                        <ListItem>
                          Select "Strongly Disagree" if the statement is
                          definitely false or if you strongly disagree.
                        </ListItem>
                        <ListItem>
                          Select "Disagree" if the statement is mostly false or
                          if you disagree.
                        </ListItem>
                        <ListItem>
                          Select "Neutral" if the statement is about equally
                          true or false, if you cannot decide or if you are
                          neutral on the statement.
                        </ListItem>
                        <ListItem>
                          Select "Agree" if the statement is mostly true or if
                          you agree.
                        </ListItem>
                        <ListItem>
                          Select "Strongly Agree" if the statement is definitely
                          true or if you strongly agree.
                        </ListItem>
                      </UnorderedList>
                    </div>

                    <div className="my-2 cds--type-bold">
                      When responding to the statements, please take note of the
                      following:
                    </div>
                    <div className="ml-4">
                      <UnorderedList>
                        <ListItem>
                          There are no right or wrong answers.
                        </ListItem>
                        <ListItem>
                          Do not spend too much time thinking over each
                          question; give the first natural answer as it comes to
                          you.
                        </ListItem>
                        <ListItem>
                          Do not give an answer because it seems like the right
                          thing to say or what you might like to be. Please
                          describe yourself as honestly and accurately as
                          possible. Most questionnaires have ways to detect
                          attempts at faking.
                        </ListItem>
                        <ListItem>
                          At the end of the questionnaire, please click the
                          “submit” button.
                        </ListItem>
                        <ListItem>
                          Most people take about 30 minutes to complete the
                          questionnaire so do pace yourself accordingly.
                        </ListItem>
                      </UnorderedList>
                    </div>
                    <hr />
                    <div>
                      <div style={{ fontSize: '0.8rem' }}>
                        <u>Copyright Notice</u>
                      </div>
                      <div style={{ fontSize: '0.7rem' }}>
                        Adapted and reproduced by special permission of the
                        Publisher, Psychological Assessment Resources, Inc.,
                        16204 North Florida Avenue, Lutz, Florida 33549, from
                        the NEO-Personality Inventory- Revised by Paul T.Costa
                        Jr., PHD and Robert R. McCrae, PHD, Copyright 1978,
                        1985, 1989, 1991, 1992 by Psychological Assessment
                        Resources, Inc (PAR). Further reproduction is prohibited
                        without permission of PAR.
                      </div>
                    </div>
                    <div className="mt-3 cds--row align-items-center justify-content-center">
                      <Button
                        className=""
                        onClick={() => this.setState({ startAssessment: true })}
                        kind="primary"
                        disabled={
                          isThisAssessCompletedByUser || questions == null
                        }>
                        Start
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="m-4 p-4">
                {showCongrats && (
                  <div className="d-flex flex-column justify-content-center align-items-center sub_div_height overflow-hidden p-3 text-center ">
                    <h2 className="cds--type-bold">Thank you!</h2>
                    <div className="">Your responses have been submitted!</div>
                  </div>
                )}

                {!showCongrats &&
                  questions &&
                  Array.isArray(questions) &&
                  questions.length > 0 && (
                    <div className="mb-3">
                      <div className="cds--type-bold">
                        Page {currPage} of{' '}
                        {Math.ceil(questions?.length / pageLimit)}
                      </div>
                      <hr />
                      <div className="feedback-table">
                        {questions?.map((itm, idx) => {
                          const { questionId, question, response } = itm || {};
                          const { value: answer } = response || {};
                          // const disableInput =
                          //   answer || isThisAssessCompletedByUser;
                          const showQuestion =
                            idx < currPage * pageLimit &&
                            idx >= currPage * pageLimit - pageLimit;

                          return (
                            (isThisAssessCompletedByUser || showQuestion) && (
                              <div className="feedback-row">
                                <div className="d-flex align-items-center">
                                  <div className="h2">
                                    <span className="text-danger">* </span>{' '}
                                    {idx + 1}.
                                  </div>
                                  <div className="h5 ml-3 font-weight-bold">
                                    {renderHtml({ __html: question })}
                                  </div>
                                </div>

                                {questionConfigOptions &&
                                  questionConfigOptions &&
                                  Array.isArray(questionConfigOptions) && (
                                    <div className="ml-3">
                                      <RadioButtonGroup
                                        id={`softskill-${questionId}`}
                                        name={`softskill-${questionId}`}
                                        orientation="horizontal"
                                        onChange={(value) =>
                                          this.handleOnChangeRadioBtn(
                                            value,
                                            questionId
                                          )
                                        }
                                        valueSelected={
                                          this.state[`softskill-${questionId}`]
                                        }
                                        defaultSelected="select"
                                        className="mt-2 ml-3">
                                        {questionConfigOptions.map(
                                          (item, idx) => (
                                            <RadioButton
                                              name={`${item.value}-${idx}-${questionId}`}
                                              id={`${item.value}-${idx}-${questionId}`}
                                              key={`${item.value}-${idx}-${questionId}`}
                                              value={item.value}
                                              labelText={
                                                <div className="font-size-l">
                                                  {item.label}
                                                </div>
                                              }
                                              className="mt-2"
                                              disabled={
                                                isThisAssessCompletedByUser
                                              }
                                            />
                                          )
                                        )}
                                      </RadioButtonGroup>
                                      {this.state[
                                        `softskill-${questionId}-isInvalid`
                                      ] && (
                                        <div
                                          className="ml-4 mt-1 text-danger"
                                          style={{ fontSize: '0.8rem' }}>
                                          Please select valid option.
                                        </div>
                                      )}
                                    </div>
                                  )}
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                  )}
                {!showCongrats && !isThisAssessCompletedByUser && (
                  <div
                    className={
                      this.state.isAtBottom ? 'static-bottom' : 'fixed-bottom'
                    }>
                    <div className="">
                      {currPage * pageLimit - pageLimit > 0 && (
                        <Button onClick={() => this.handlePreviousPage()}>
                          Previous Page
                        </Button>
                      )}
                    </div>
                    <div className="d-flex flex-column align-items-end">
                      <div>
                        <Button
                          className=""
                          onClick={() => this.handleNextPage(showSubmitBtn)}>
                          {showSubmitBtn ? 'Submit' : 'Next Page'}
                        </Button>
                      </div>
                      {hasInvalidResponses && (
                        <div
                          className="mt-2 text-danger"
                          style={{ fontSize: '0.8rem' }}>
                          Please check each field and fix any errors that are
                          showing.
                        </div>
                      )}
                      <div></div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.Assessments.loading,
});

const mapDispatchToProps = {
  getAsessmentInfoByUUID,
  generateAssessmentOTP,
  getAssessmentInfoByOTP,
  saveCandResponseByIndvQues,
  submitCandAssessmentResponse,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CandidateSoftskillAssessment)
);
