import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button,
  InlineNotification,
  TextArea,
  Table,
  Accordion,
  AccordionItem,
} from '@carbon/react';
import {
  getAsessmentInfoByUUID,
  upsertIndvAssSectionResponses,
} from '../../../actions/PscPeerFeedback';
import Dropdown from '../../../components/common/Dropdown';
import JDProcessOverlay from '../../../components/common/JDProcessOverlay';
import _ from 'lodash';
import GenericDataTable from '../../../components/common/GenericDataTable';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './RatingSlider.css'; // For custom CSS
import './PscPeerFeedback.css';
import { ChevronDownOutline, ChevronUpOutline } from '@carbon/icons-react';
import { motion, AnimatePresence } from 'framer-motion';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const serverBusyMessage =
  'Unable to process the request, please try again. If the issue persists, please contact support@x0pa.com';

const renderHtml = (htmlObject) => {
  return (
    <div
      className="html-render-content"
      dangerouslySetInnerHTML={{ __html: htmlObject }}
    />
  );
};

class PscPeerFeedbackAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 1,
      isVertical: window.innerWidth <= 768, // Set initial orientation based on window size
      selectedCandidates: {},
      areAllInputsValid: true,
      sectionThreeRecords: [],
    };
  }

  moveCard = (index, direction) => {
    const { cards } = this.state;
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= cards.length) return;

    const newCards = [...cards];
    [newCards[index], newCards[newIndex]] = [
      newCards[newIndex],
      newCards[index],
    ];

    this.setState({ cards: newCards });
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleGetAssessmentInfo();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  fetchFromLocalStorage = () => {
    const { match: { params: { id, candidateId, sectionKey } = {} } = {} } =
      this.props;
    return JSON.parse(
      localStorage.getItem(`${id}-${candidateId}-${sectionKey}`)
    );
  };

  saveToLocalStorage = (updateObj) => {
    const { match: { params: { id, candidateId, sectionKey } = {} } = {} } =
      this.props;
    const { data: localDataRaw = {} } = this.fetchFromLocalStorage() || {};

    const data = { ...localDataRaw, ...updateObj };
    const finalData = {
      data,
      timeStamp: new Date().toISOString(),
    };
    localStorage.setItem(
      `${id}-${candidateId}-${sectionKey}`,
      JSON.stringify(finalData)
    );
  };

  removeFromLocalStorage = () => {
    const { match: { params: { id, candidateId, sectionKey } = {} } = {} } =
      this.props;
    localStorage.removeItem(`${id}-${candidateId}-${sectionKey}`);
  };

  handleResize = () => {
    this.setState({ isVertical: window.innerWidth < 768 });
  };

  handleNotification = (nshow, nkind, nmessage) => {
    this.setState({
      notifShow: nshow,
      notifKind: nkind,
      notifMessage: nmessage,
    });
    setTimeout(() => {
      this.setState({
        notifShow: false,
        notifKind: nkind,
        notifMessage: nmessage,
      });
    }, 5000);
  };

  handleErrorNotification = (err) => {
    console.error(err);
    window.scrollTo(0, 0);
    const { error, message } = err || {};
    let errorMessage = message;
    if (!errorMessage && error) {
      const { message } = error || {};
      errorMessage = message;
    }
    if (errorMessage) {
      errorMessage = `${errorMessage} Please try again. If issue persists then contact us at support@x0pa.com`;
    }
    const defaultMessage =
      'Unknown error occured. Please try again. If issue persists then contact us at support@x0pa.com';

    this.handleNotification(true, 'error', errorMessage || defaultMessage);
  };

  populateDataFromGetAPI = (currentSection = {}) => {
    console.log('currentSection $$', currentSection);
    const {
      history,
      match: { params: { sectionKey, id, candidateId } = {} } = {},
    } = this.props;

    const {
      questions = [],
      peerList = [],
      responses = [],
    } = currentSection || {};

    let currCandResponses = [];

    if (sectionKey === '1' || sectionKey === '2') {
      currCandResponses = _.values(
        peerList?.find((itm) => itm?.candidateId == candidateId)?.responses
      );
    } else if (sectionKey === '3') {
      currCandResponses = responses;
    }

    const updateStateObj = {};

    console.log('currCandResponses $$', currCandResponses);

    if (sectionKey === '1' || sectionKey === '2' || sectionKey === '3') {
      if (
        currCandResponses &&
        Array.isArray(currCandResponses) &&
        currCandResponses.length > 0
      ) {
        currCandResponses.forEach((itm) => {
          if (sectionKey === '1' || sectionKey === '2') {
            updateStateObj[`rating-${itm?.questionId}`] = itm?.value;
          } else if (sectionKey === '3') {
            const peerCandObj = questions?.[0]?.value?.choices?.find(
              (cand) => cand?.candidateId === itm?.evaluatedCandidateId
            );
            updateStateObj[`peer-cand-name-${itm?.evaluatedCandidateId}`] = {
              label: peerCandObj?.fullName,
              value: peerCandObj?.candidateId,
            };

            updateStateObj[`comment-${itm?.evaluatedCandidateId}`] =
              itm?.comments;
          }
        });
      }
    } else if (sectionKey === '4') {
      if (responses && Array.isArray(responses) && responses.length > 0) {
        const candidateObj = _.find(_.flatMap(questions?.[0]?.value?.choices), {
          candidateId: responses?.[0]?.evaluatedCandidateId,
        });
        updateStateObj.peerCand = {
          label: candidateObj?.fullName,
          value: candidateObj?.candidateId,
        };
        updateStateObj.comments = responses?.[0]?.comments;
      }
    }

    console.log('updateStateObj $$', updateStateObj);

    this.setState({ ...updateStateObj });
  };

  populateDataFromLocalStorage = (localDataRaw = {}) => {
    this.setState({ ...localDataRaw });
  };

  handleGetAssessmentInfo = () => {
    const { match: { params: { id, sectionKey } = {} } = {} } = this.props;
    this.props
      .getAsessmentInfoByUUID(id)
      .then((res) => {
        if (res && !res.error) {
          const currSectionInfo = this.fetchFromLocalStorage();
          const { data: localDataRaw = {}, timeStamp: localTimeStamp } =
            currSectionInfo || {};
          const { sections, candidateInfo, draftedAt } = res || {};
          const currentSection = sections?.[sectionKey] || {};
          const {
            headers = [],
            questions = [],
            peerList = [],
          } = currentSection || {};

          const updateStateObj = {
            headers,
            questions,
            peerList,
            candidateInfo,
          };

          if (sectionKey === '3')
            updateStateObj.sectionThreeRecords =
              questions?.[0]?.value?.choices || [];

          this.setState(updateStateObj);

          if (!localTimeStamp) {
            this.populateDataFromGetAPI(currentSection);
            return;
          }

          const storedTime = localTimeStamp && new Date(localTimeStamp);
          const serverTime = draftedAt && new Date(draftedAt);

          console.log(
            'storedTime',
            storedTime,
            'serverTime',
            serverTime,
            serverTime > storedTime
          );

          if (storedTime && serverTime) {
            if (serverTime > storedTime)
              this.populateDataFromGetAPI(currentSection);
            else this.populateDataFromLocalStorage(localDataRaw);
          }
        } else {
          this.handleErrorNotification(res?.error);
        }
      })
      .catch((err) => {
        console.error(err);
        this.handleErrorNotification(err);
      });
  };

  handleChange = (name, value) => {
    const updateObj = { [`${name}`]: value };
    console.log('updateObj onchange', updateObj, typeof value);
    this.setState({ ...updateObj });
    this.saveToLocalStorage(updateObj);
  };

  validateInputs = () => {
    const {
      history,
      match: { params: { sectionKey, id, candidateId } = {} } = {},
    } = this.props;
    const { questions, sectionThreeRecords } = this.state;

    const invalidResponses = {};

    if (sectionKey === '1' || sectionKey === '2') {
      questions.forEach((element, idx) => {
        const { questionId } = element || {};
        invalidResponses[`rating-${questionId}`] =
          !this.state[`rating-${questionId}`];
      });
    } else if (sectionKey === '3') {
      const invalidResponses = {};
      sectionThreeRecords.forEach((element, idx) => {
        const { candidateId: candId } = element || {};
        invalidResponses[`peer-cand-name-${candId}`] =
          !this.state[`peer-cand-name-${candId}`]?.value;
      });
    } else if (sectionKey === '4') {
      invalidResponses['peerCand'] = !this.state.peerCand?.value;
    }

    const areAllInputsValid = !Object.values(invalidResponses).some(Boolean);

    this.setState({ invalidResponses, areAllInputsValid });

    return areAllInputsValid;
  };

  handleSubmit = (saveAsDraft) => {
    const {
      history,
      match: { params: { sectionKey, id, candidateId } = {} } = {},
    } = this.props;
    const { questions, candidateInfo, sectionThreeRecords } = this.state;

    const areAllInputsValid = saveAsDraft ? true : this.validateInputs();

    if (!areAllInputsValid) return;

    const data = {
      publicId: id,
      sectionNo: sectionKey && Number(sectionKey),
      status: saveAsDraft ? 'Draft' : 'Completed',
    };

    if (sectionKey === '1' || sectionKey === '2') {
      const responses = [];
      questions.forEach((element, idx) => {
        const { questionId, orderNo } = element || {};
        const obj = {
          questionId,
          orderNo,
        };

        obj.value = this.state[`rating-${questionId}`];
        data.evaluatedCandidateId =
          sectionKey === '2' ? candidateInfo?.candidateId : candidateId;

        responses.push(obj);
      });

      data.responses = responses;
    } else if (sectionKey === '3') {
      const responses = [];
      sectionThreeRecords.forEach((element, idx) => {
        const { orderNo, candidateId: candId } = element || {};
        const obj = {
          questionId: questions?.[0]?.questionId,
          orderNo,
        };

        obj.evaluatedCandidateId =
          this.state[`peer-cand-name-${candId}`]?.value;
        obj.rank = idx + 1;
        obj.comments = this.state[`comment-${candId}`];

        responses.push(obj);
      });

      data.responses = responses;
    } else if (sectionKey === '4') {
      data.questionId = questions?.[0]?.questionId;
      data.evaluatedCandidateId = this.state.peerCand?.value;
      data.comments = this.state.comments;
    }

    this.props
      .upsertIndvAssSectionResponses(data)
      .then((res) => {
        const { error, message } = res || {};
        if (res && !res.error) {
          this.handleNotification(true, 'success', message);
          if (!saveAsDraft)
            history?.push(`/psc-peer-feedback/${id}/assessments`);
        } else {
          this.handleNotification(true, 'error', error?.message);
        }
      })
      .catch((err) => {
        this.handleErrorNotification(err);
      });
  };

  handleDropdownChange = (metaValue, name) => {
    const { match: { params: { sectionKey } = {} } = {} } = this.props;
    this.setState((prevState) => {
      const updateObj = { [name]: metaValue };
      if (sectionKey === '3') {
        updateObj.selectedCandidates = {
          ...prevState.selectedCandidates,
          [name]: metaValue,
        };
      }
      this.saveToLocalStorage(updateObj);
      return { ...updateObj };
    });
  };

  handleMoveRow = (index, direction) => {
    const { sectionThreeRecords } = this.state;
    const newSecThreeRecords = [...sectionThreeRecords];
    const swapIndex = index + direction;

    // Ensure index is within valid bounds
    if (swapIndex < 0 || swapIndex >= newSecThreeRecords.length) return;

    // Swap rows
    [newSecThreeRecords[index], newSecThreeRecords[swapIndex]] = [
      newSecThreeRecords[swapIndex],
      newSecThreeRecords[index],
    ];

    const updateObj = { sectionThreeRecords: newSecThreeRecords };
    this.setState({ ...updateObj });
    this.saveToLocalStorage(updateObj);
  };

  render() {
    const { history, match: { params: { sectionKey, id } = {} } = {} } =
      this.props;
    const { push } = history || {};
    const {
      notifShow,
      notifKind,
      notifMessage,
      isVertical,
      sections,
      headers = [],
      questions = [],
      peerList,
      invalidResponses,
      areAllInputsValid,
      sectionThreeRecords,
    } = this.state;

    const selectedValues = Object.values(this.state.selectedCandidates)
      ?.filter(Boolean)
      ?.map((i) => i?.value);

    const settings = {
      centerMode: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      //  allowArrows:  true
    };

    return (
      <div>
        {notifShow && (
          <InlineNotification
            lowContrast
            hideCloseButton
            className="m-0 mt-3 p-0 w-100"
            kind={notifKind}
            title={notifMessage}
            onCloseButtonClick={() => {
              this.setState({ notifShow: false });
            }}
          />
        )}

        <div className="mt-4">
          <div className="mb-2">
            <a
              className="xpa-link"
              href={`/psc-peer-feedback/${id}/assessments`}>
              &larr; Go Back
            </a>
          </div>
          {_.map(headers, (value, key, idx) => {
            return (
              <div className="mb-3 mt-3">
                <h5 className="h6 mb-1 font-weight-bold">
                  {value?.value?.title}
                </h5>
                <div>{renderHtml(value?.value?.value)}</div>
              </div>
            );
          })}

          <div className="mt-5">
            {(sectionKey === '1' || sectionKey === '2') && (
              <>
                {_.map(questions, (value, key, index) => {
                  const { sectionNo, questionId } = value || {};
                  const {
                    title,
                    ratings = [],
                    leftIndicators,
                    rightIndicators,
                  } = value?.value || {};
                  return (
                    <div className="mb-3 cds--tile">
                      <h5 className="mb-2">{title}</h5>
                      <div className="cds--row d-flex align-items-center">
                        {/* Left Indicators */}
                        <div className="cds--col-lg-6 cds--col-md-6 cds--col-sm-16 cds--col-xs-16 left-bg-color d-flex flex-column justify-content-center align-items-center">
                          {renderHtml(leftIndicators)}
                        </div>

                        {/* Rating Slider (Centered) */}
                        <div
                          className={`cds--col-lg-4 cds--col-md-4 cds--col-sm-16 cds--col-xs-16 d-flex flex-column justify-content-center align-items-center ${
                            isVertical ? 'my-3' : ''
                          }`}>
                          {/* <h3>Rating: {this.state[`rating-${questionId}`]}</h3> */}
                          <Slider
                            min={1}
                            max={6}
                            value={this.state[`rating-${questionId}`]}
                            vertical={isVertical}
                            onChange={(val) =>
                              this.handleChange(`rating-${questionId}`, val)
                            }
                            marks={ratings.reduce((acc, value) => {
                              acc[value] = value.toString();
                              return acc;
                            }, {})}
                          />
                        </div>

                        {/* Right Indicators */}
                        <div className="cds--col-lg-6 cds--col-md-6 cds--col-sm-16 cds--col-xs-16 d-flex flex-column justify-content-center align-items-center">
                          {renderHtml(rightIndicators)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}

            {sectionKey === '3' && (
              <div>
                {console.log('sectionThreeRecords', sectionThreeRecords)}

                <div className="w-full max-w-sm mx-auto mt-6 space-y-4">
                  {sectionThreeRecords &&
                  Array.isArray(sectionThreeRecords) &&
                  sectionThreeRecords.length > 0 ? (
                    <AnimatePresence>
                      <Accordion className="css-gba-accr-z" align="start">
                        {sectionThreeRecords.map((itm, idx) => {
                          const { candidateId: candId } = itm || {};
                          const candidateName =
                            this.state[`peer-cand-name-${candId}`]?.label;
                          return (
                            <motion.div
                              key={candId}
                              layout
                              initial={{ opacity: 0, y: -10 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: 10 }}
                              transition={{
                                type: 'spring',
                                stiffness: 300,
                                damping: 20,
                              }}
                              className="shadow-md rounded rounded-lg ">
                              <AccordionItem
                                className=""
                                title={
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="">
                                      <div className="cds--type-zeta">
                                        Rank #{idx + 1}{' '}
                                        {candidateName && `- ${candidateName}`}
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center xpa-action-btns">
                                      <div className="d-flex">
                                        {idx > 0 && (
                                          <Button
                                            id={`up-arrow-${idx}`}
                                            kind="ghost"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              this.handleMoveRow(idx, -1);
                                              document.activeElement.blur();
                                            }}
                                            size="small"
                                            hasIconOnly
                                            tooltipPosition="bottom"
                                            iconDescription="Move Up"
                                            className="p-0">
                                            <ChevronUpOutline />
                                          </Button>
                                        )}
                                        {idx <
                                          sectionThreeRecords.length - 1 && (
                                          <Button
                                            id={`down-arrow-${idx}`}
                                            kind="ghost"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              this.handleMoveRow(idx, 1);
                                              document.activeElement.blur();
                                            }}
                                            size="small"
                                            hasIconOnly
                                            tooltipPosition="bottom"
                                            iconDescription="Move Down"
                                            className="p-0">
                                            <ChevronDownOutline />
                                          </Button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                }>
                                <>
                                  <div className="cds--row align-items-center mt-2">
                                    <div className="cds--col-md-2 cds--col-sm-3 cds--col-xs-3 cds--type-zeta">
                                      Peer Candidate Name
                                    </div>
                                    <div className="cds--col-lg-12 cds--col-md-6 cds--col-sm-6 cds--col-xs-6">
                                      <Dropdown
                                        id={`peer-cand-name-${candId}`}
                                        title=""
                                        custom
                                        mainClassName="cds--form-item mb-0"
                                        className="w-100"
                                        // titleClass="mb-2"
                                        // labelClass="pt-2"
                                        onInputChange={() => {}}
                                        options={
                                          sectionThreeRecords
                                            ?.filter(
                                              (itm) =>
                                                !selectedValues.includes(
                                                  itm.candidateId
                                                ) ||
                                                this.state[
                                                  `peer-cand-name-${candId}`
                                                ] === itm.candidateId
                                            )
                                            ?.map((itm, idx) => {
                                              return {
                                                label: itm?.fullName,
                                                value: itm?.candidateId,
                                              };
                                            }) || []
                                        }
                                        name={`peer-cand-name-${candId}`}
                                        placeholder="Peer Candidate Name"
                                        onChange={(value, e, name) =>
                                          this.handleDropdownChange(e, name)
                                        }
                                        selectedValue={
                                          this.state[`peer-cand-name-${candId}`]
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="cds--row align-items-center mt-2">
                                    <div className="cds--col-md-2 cds--col-sm-3 cds--col-xs-3 cds--type-zeta">
                                      Comments
                                    </div>
                                    <div className="cds--col-lg-12 cds--col-md-6 cds--col-sm-6 cds--col-xs-6">
                                      <TextArea
                                        className=""
                                        key={`comment-${candId}`}
                                        name={`comment-${candId}`}
                                        labelText=""
                                        hideLabel
                                        onChange={(e) =>
                                          this.handleChange(
                                            e?.target?.name,
                                            e?.target?.value
                                          )
                                        }
                                        placeholder="Type Here"
                                        // invalid={this.state[`valid-${ratingCriteriaId}`]}
                                        // invalidText="Please enter valid answer"
                                        id={`comment-${candId}`}
                                        value={this.state[`comment-${candId}`]}
                                        rows={4}
                                      />
                                    </div>
                                  </div>
                                </>
                              </AccordionItem>
                            </motion.div>
                          );
                        })}
                      </Accordion>
                    </AnimatePresence>
                  ) : (
                    []
                  )}
                </div>
              </div>
            )}

            {sectionKey === '4' && (
              <div>
                <div className="container mt-4">
                  {/* Desktop View: Grid Layout */}
                  <div className="d-none d-md-flex flex-wrap gap-3 justify-content-center">
                    {_.map(
                      questions?.[0]?.value?.choices,
                      (val, key, index) => {
                        return (
                          <div
                            key={index}
                            className="card shadow-sm p-2"
                            style={{ width: '250px' }}>
                            <Table size="compact">
                              <thead>
                                <tr>
                                  <th className="text-center">Group {key}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {val?.map((itm, idx) => (
                                  <tr key={idx}>
                                    <td className="text-center">
                                      {itm?.fullName}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        );
                      }
                    )}
                  </div>

                  {/* Mobile View: Swiper Carousel */}
                  <div className="d-md-none mt-3">
                    <div className="carousel-container">
                      <SlickSlider {...settings}>
                        {_.map(questions?.[0]?.value?.choices, (val, key) => (
                          <div key={key} className="p-2">
                            <Table size="compact">
                              <thead>
                                <tr>
                                  <th className="text-center">Group {key}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {val?.map((itm, idx) => (
                                  <tr key={idx}>
                                    <td className="text-center">
                                      {itm?.fullName}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        ))}
                      </SlickSlider>
                    </div>
                  </div>
                  <div className="mt-5">
                    <Dropdown
                      id="peerCand"
                      title={
                        <div className="cds--type-zeta">Choose one person</div>
                      }
                      custom
                      mainClassName="mb-0"
                      className="w-50"
                      titleClass="mb-2"
                      labelClass="pt-2 ml-0"
                      onInputChange={() => {}}
                      options={_.flatMap(questions, (q) =>
                        _.flatMap(q.value.choices, (group) =>
                          _.map(group, ({ candidateId, fullName }) => ({
                            label: fullName,
                            value: candidateId,
                          }))
                        )
                      )}
                      name="peerCand"
                      placeholder="Peer Candidate"
                      onChange={(value, e, name) =>
                        this.handleDropdownChange(e, name)
                      }
                      selectedValue={this.state.peerCand}
                    />
                  </div>
                  <div className="mt-3">
                    <div className="cds--type-zeta mb-2">Comments</div>
                    <TextArea
                      className=""
                      key="comments"
                      name="comments"
                      labelText=""
                      hideLabel
                      onChange={(e) =>
                        this.handleChange(e?.target?.name, e?.target?.value)
                      }
                      placeholder="Type Here"
                      // invalid={this.state[`valid-${ratingCriteriaId}`]}
                      // invalidText="Please enter valid answer"
                      id="comments"
                      value={this.state.comments}
                      rows={4}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="fixed-bottom">
              <div className="">
                <Button onClick={() => this.handleSubmit(true)}>
                  Save as Draft
                </Button>
              </div>
              <div className="d-flex flex-column align-items-end">
                <div>
                  <Button className="" onClick={() => this.handleSubmit()}>
                    Submit
                  </Button>
                  {!areAllInputsValid && (
                    <div
                      className="mt-2 text-danger"
                      style={{ fontSize: '0.8rem' }}>
                      Please check all fields are filled.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.PscPeerFeedback.loading,
  assessmentInfo: state.PscPeerFeedback.assessmentInfo,
});

const mapDispatchToProps = {
  getAsessmentInfoByUUID,
  upsertIndvAssSectionResponses,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PscPeerFeedbackAssessment)
);
