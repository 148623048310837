import {
  REQUEST_GET_PSC_ASSESSMENT_INFO_BY_UUID,
  SUCCESS_GET_PSC_ASSESSMENT_INFO_BY_UUID,
  ERROR_GET_PSC_ASSESSMENT_INFO_BY_UUID,
  REQUEST_UPSERT_INDV_SECTION_RESPONSES,
  SUCCESS_UPSERT_INDV_SECTION_RESPONSES,
  ERROR_UPSERT_INDV_SECTION_RESPONSES,
  REQUEST_SUBMIT_ENTIRE_ASSESSMENT,
  SUCCESS_SUBMIT_ENTIRE_ASSESSMENT,
  ERROR_SUBMIT_ENTIRE_ASSESSMENT,
  REQUEST_SUBMIT_RETAKE_REQUEST,
  SUCCESS_SUBMIT_RETAKE_REQUEST,
  ERROR_SUBMIT_RETAKE_REQUEST,
} from '../actions/actionTypes';

const initalState = {
  loading: false,
  assessmentInfo: {},
};

const PscPeerFeedback = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_GET_PSC_ASSESSMENT_INFO_BY_UUID:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SUCCESS_GET_PSC_ASSESSMENT_INFO_BY_UUID:
      return {
        ...state,
        loading: false,
        error: null,
        assessmentInfo: payload,
      };
    case ERROR_GET_PSC_ASSESSMENT_INFO_BY_UUID:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_UPSERT_INDV_SECTION_RESPONSES:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SUCCESS_UPSERT_INDV_SECTION_RESPONSES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPSERT_INDV_SECTION_RESPONSES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_SUBMIT_ENTIRE_ASSESSMENT:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SUCCESS_SUBMIT_ENTIRE_ASSESSMENT:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_SUBMIT_ENTIRE_ASSESSMENT:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_SUBMIT_RETAKE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_SUBMIT_RETAKE_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_SUBMIT_RETAKE_REQUEST:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default PscPeerFeedback;
