export const REQUEST_GET_ASSESSMENT_SCENARIOS =
  'REQUEST_GET_ASSESSMENT_SCENARIOS';
export const SUCCESS_GET_ASSESSMENT_SCENARIOS =
  'SUCCESS_GET_ASSESSMENT_SCENARIOS';
export const ERROR_GET_ASSESSMENT_SCENARIOS = 'ERROR_GET_ASSESSMENT_SCENARIOS';

export const REQUEST_ADD_ASSESSMENT_CANDIDATE =
  'REQUEST_ADD_ASSESSMENT_CANDIDATE';
export const SUCCESS_ADD_ASSESSMENT_CANDIDATE =
  'SUCCESS_ADD_ASSESSMENT_CANDIDATE';
export const ERROR_ADD_ASSESSMENT_CANDIDATE = 'ERROR_ADD_ASSESSMENT_CANDIDATE';

export const REQUEST_UPDATE_ASSESSMENT_CANDIDATE =
  'REQUEST_UPDATE_ASSESSMENT_CANDIDATE';
export const SUCCESS_UPDATE_ASSESSMENT_CANDIDATE =
  'SUCCESS_UPDATE_ASSESSMENT_CANDIDATE';
export const ERROR_UPDATE_ASSESSMENT_CANDIDATE =
  'ERROR_UPDATE_ASSESSMENT_CANDIDATE';

export const REQUEST_ALL_COUNTRIES = 'REQUEST_ALL_COUNTRIES';
export const SUCCESS_ALL_COUNTRIES = 'SUCCESS_ALL_COUNTRIES';
export const ERROR_ALL_COUNTRIES = 'ERROR_ALL_COUNTRIES';

export const REQUEST_GET_ASSESSMENT_CANDIDATE_INFO =
  'REQUEST_GET_ASSESSMENT_CANDIDATE_INFO';
export const SUCCESS_GET_ASSESSMENT_CANDIDATE_INFO =
  'SUCCESS_GET_ASSESSMENT_CANDIDATE_INFO';
export const ERROR_GET_ASSESSMENT_CANDIDATE_INFO =
  'ERROR_GET_ASSESSMENT_CANDIDATE_INFO';

export const REQUEST_GET_ASSESS_COMPANY_TYPES =
  'REQUEST_GET_ASSESS_COMPANY_TYPES';
export const SUCCESS_GET_ASSESS_COMPANY_TYPES =
  'SUCCESS_GET_ASSESS_COMPANY_TYPES';
export const ERROR_GET_ASSESS_COMPANY_TYPES = 'ERROR_GET_ASSESS_COMPANY_TYPES';

export const REQUEST_GET_ASSESS_COMPANIES = 'REQUEST_GET_ASSESS_COMPANIES';
export const SUCCESS_GET_ASSESS_COMPANIES = 'SUCCESS_GET_ASSESS_COMPANIES';
export const ERROR_GET_ASSESS_COMPANIES = 'ERROR_GET_ASSESS_COMPANIES';

export const REQUEST_CREATE_ASSESS_COMPANY = 'REQUEST_CREATE_ASSESS_COMPANY';
export const SUCCESS_CREATE_ASSESS_COMPANY = 'SUCCESS_CREATE_ASSESS_COMPANY';
export const ERROR_CREATE_ASSESS_COMPANY = 'ERROR_CREATE_ASSESS_COMPANY';

export const REQUEST_GET_ASSESSMENT_INFO_BY_UUID =
  'REQUEST_GET_ASSESSMENT_INFO_BY_UUID';
export const SUCCESS_GET_ASSESSMENT_INFO_BY_UUID =
  'SUCCESS_GET_ASSESSMENT_INFO_BY_UUID';
export const ERROR_GET_ASSESSMENT_INFO_BY_UUID =
  'ERROR_GET_ASSESSMENT_INFO_BY_UUID';

export const REQUEST_GET_ASSESSMENT_OTP = 'REQUEST_GET_ASSESSMENT_OTP';
export const SUCCESS_GET_ASSESSMENT_OTP = 'SUCCESS_GET_ASSESSMENT_OTP';
export const ERROR_GET_ASSESSMENT_OTP = 'ERROR_GET_ASSESSMENT_OTP';

export const REQUEST_GET_ASSESSMENT_INFO_BY_OTP =
  'REQUEST_GET_ASSESSMENT_INFO_BY_OTP';
export const SUCCESS_GET_ASSESSMENT_INFO_BY_OTP =
  'SUCCESS_GET_ASSESSMENT_INFO_BY_OTP';
export const ERROR_GET_ASSESSMENT_INFO_BY_OTP =
  'ERROR_GET_ASSESSMENT_INFO_BY_OTP';

export const REQUEST_SAVE_CAND_RESPONSE_BY_QUES =
  'REQUEST_SAVE_CAND_RESPONSE_BY_QUES';
export const SUCCESS_SAVE_CAND_RESPONSE_BY_QUES =
  'SUCCESS_SAVE_CAND_RESPONSE_BY_QUES';
export const ERROR_SAVE_CAND_RESPONSE_BY_QUES =
  'ERROR_SAVE_CAND_RESPONSE_BY_QUES';

export const REQUEST_SUBMIT_CAND_ASSESS_RESPONSE =
  'REQUEST_SUBMIT_CAND_ASSESS_RESPONSE';
export const SUCCESS_SUBMIT_CAND_ASSESS_RESPONSE =
  'SUCCESS_SUBMIT_CAND_ASSESS_RESPONSE';
export const ERROR_SUBMIT_CAND_ASSESS_RESPONSE =
  'ERROR_SUBMIT_CAND_ASSESS_RESPONSE';

export const REQUEST_VERIFY_EMAIL_SEND_OTP = 'REQUEST_VERIFY_EMAIL_SEND_OTP';
export const SUCCESS_VERIFY_EMAIL_SEND_OTP = 'SUCCESS_VERIFY_EMAIL_SEND_OTP';
export const ERROR_VERIFY_EMAIL_SEND_OTP = 'ERROR_VERIFY_EMAIL_SEND_OTP';

export const REQUEST_VERIFY_OTP_BY_EMAIL = 'REQUEST_VERIFY_OTP_BY_EMAIL';
export const SUCCESS_VERIFY_OTP_BY_EMAIL = 'SUCCESS_VERIFY_OTP_BY_EMAIL';
export const ERROR_VERIFY_OTP_BY_EMAIL = 'ERROR_VERIFY_OTP_BY_EMAIL';

export const REQUEST_GET_FEEDBACK_ASSESSMENT_INFO_BY_ID =
  'REQUEST_GET_FEEDBACK_ASSESSMENT_INFO_BY_ID';
export const SUCCESS_GET_FEEDBACK_ASSESSMENT_INFO_BY_ID =
  'SUCCESS_GET_FEEDBACK_ASSESSMENT_INFO_BY_ID';
export const ERROR_GET_FEEDBACK_ASSESSMENT_INFO_BY_ID =
  'ERROR_GET_FEEDBACK_ASSESSMENT_INFO_BY_ID';

export const REQUEST_SUBMIT_FEEDBACK_ASSESS_RESPONSE =
  'REQUEST_SUBMIT_FEEDBACK_ASSESS_RESPONSE';
export const SUCCESS_SUBMIT_FEEDBACK_ASSESS_RESPONSE =
  'SUCCESS_SUBMIT_FEEDBACK_ASSESS_RESPONSE';
export const ERROR_SUBMIT_FEEDBACK_ASSESS_RESPONSE =
  'ERROR_SUBMIT_FEEDBACK_ASSESS_RESPONSE';

export const REQUEST_VALIDATE_PAE_ASSESS_LINK =
  'REQUEST_VALIDATE_PAE_ASSESS_LINK';

export const SUCCESS_VALIDATE_PAE_ASSESS_LINK =
  'SUCCESS_VALIDATE_PAE_ASSESS_LINK';
export const ERROR_VALIDATE_PAE_ASSESS_LINK = 'ERROR_VALIDATE_PAE_ASSESS_LINK';

export const REQUEST_VALIDATE_PAE_CAND_BY_EMAIL_NRIC =
  'REQUEST_VALIDATE_PAE_CAND_BY_EMAIL_NRIC';
export const SUCCESS_VALIDATE_PAE_CAND_BY_EMAIL_NRIC =
  'SUCCESS_VALIDATE_PAE_CAND_BY_EMAIL_NRIC';
export const ERROR_VALIDATE_PAE_CAND_BY_EMAIL_NRIC =
  'ERROR_VALIDATE_PAE_CAND_BY_EMAIL_NRIC';

export const REQUEST_UPLOAD_CAND_NRIC = 'REQUEST_UPLOAD_CAND_NRIC';
export const SUCCESS_UPLOAD_CAND_NRIC = 'SUCCESS_UPLOAD_CAND_NRIC';
export const ERROR_UPLOAD_CAND_NRIC = 'ERROR_UPLOAD_CAND_NRIC';

export const REQUEST_GET_CAND_ASSESS_STATUS = 'REQUEST_GET_CAND_ASSESS_STATUS';
export const SUCCESS_GET_CAND_ASSESS_STATUS = 'SUCCESS_GET_CAND_ASSESS_STATUS';
export const ERROR_GET_CAND_ASSESS_STATUS = 'ERROR_GET_CAND_ASSESS_STATUS';

export const REQUEST_GET_PSC_ASSESSMENT_INFO_BY_UUID =
  'REQUEST_GET_PSC_ASSESSMENT_INFO_BY_UUID';
export const SUCCESS_GET_PSC_ASSESSMENT_INFO_BY_UUID =
  'SUCCESS_GET_PSC_ASSESSMENT_INFO_BY_UUID';
export const ERROR_GET_PSC_ASSESSMENT_INFO_BY_UUID =
  'ERROR_GET_PSC_ASSESSMENT_INFO_BY_UUID';

export const REQUEST_UPSERT_INDV_SECTION_RESPONSES =
  'REQUEST_UPSERT_INDV_SECTION_RESPONSES';
export const SUCCESS_UPSERT_INDV_SECTION_RESPONSES =
  'SUCCESS_UPSERT_INDV_SECTION_RESPONSES';
export const ERROR_UPSERT_INDV_SECTION_RESPONSES =
  'ERROR_UPSERT_INDV_SECTION_RESPONSES';

export const REQUEST_SUBMIT_ENTIRE_ASSESSMENT =
  'REQUEST_SUBMIT_ENTIRE_ASSESSMENT';
export const SUCCESS_SUBMIT_ENTIRE_ASSESSMENT =
  'SUCCESS_SUBMIT_ENTIRE_ASSESSMENT';
export const ERROR_SUBMIT_ENTIRE_ASSESSMENT = 'ERROR_SUBMIT_ENTIRE_ASSESSMENT';

export const REQUEST_SUBMIT_RETAKE_REQUEST = 'REQUEST_SUBMIT_RETAKE_REQUEST';
export const SUCCESS_SUBMIT_RETAKE_REQUEST = 'SUCCESS_SUBMIT_RETAKE_REQUEST';
export const ERROR_SUBMIT_RETAKE_REQUEST = 'ERROR_SUBMIT_RETAKE_REQUEST';
