import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button,
  InlineNotification,
  Accordion,
  AccordionItem,
} from '@carbon/react';
import {
  getAsessmentInfoByUUID,
  submitEntireAssessment,
} from '../../../actions/PscPeerFeedback';
import JDProcessOverlay from '../../../components/common/JDProcessOverlay';
import _ from 'lodash';
import GenericDataTable from '../../../components/common/GenericDataTable';
import { lowerAndTrim } from '../../../utils/helpers';

const serverBusyMessage =
  'Unable to process the request, please try again. If the issue persists, please contact support@x0pa.com';

const getStatusColor = (status) => {
  const statusColor =
    {
      'in progress': 'cds--tag--yellow',
      completed: 'cds--tag--green',
    }[status] || 'cds--tag--gray';
  return statusColor;
};

class PscPeerFeedbackAssessList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startAssessment: false,
      currPage: 1,
    };
  }

  componentDidMount() {
    this.handleGetAssessmentInfo();
  }

  handleNotification = (nshow, nkind, nmessage) => {
    this.setState({
      notifShow: nshow,
      notifKind: nkind,
      notifMessage: nmessage,
    });
    setTimeout(() => {
      this.setState({
        notifShow: false,
        notifKind: nkind,
        notifMessage: nmessage,
      });
    }, 5000);
  };

  handleErrorNotification = (err) => {
    console.error(err);
    window.scrollTo(0, 0);
    const { error, message } = err || {};
    let errorMessage = message;
    if (!errorMessage && error) {
      const { message } = error || {};
      errorMessage = message;
    }
    if (errorMessage) {
      errorMessage = `${errorMessage} Please try again. If issue persists then contact us at support@x0pa.com`;
    }
    const defaultMessage =
      'Unknown error occured. Please try again. If issue persists then contact us at support@x0pa.com';

    this.handleNotification(true, 'error', errorMessage || defaultMessage);
  };

  handleGetAssessmentInfo = () => {
    const { match: { params: { id } = {} } = {} } = this.props;
    this.props
      .getAsessmentInfoByUUID(id)
      .then((res) => {
        if (res && !res.error) {
          const { referenceId, sections, candidateInfo } = res || {};
          this.setState({
            sections,
            candidateInfo,
            referenceId,
          });
        } else {
          this.handleErrorNotification();
        }
      })
      .catch((err) => {
        console.error(err);
        this.handleErrorNotification(err);
      });
  };

  handleChange = (e) => {
    const { name, value } = (e && e.target) || {};
    this.setState({
      [name]: value,
    });
  };

  handleFinalSubmit = () => {
    const { match: { params: { id } = {} } = {}, history } = this.props;
    this.props
      .submitEntireAssessment({ publicId: id })
      .then((res) => {
        const { error, message } = res || {};
        if (res && !error) {
          // this.handleNotification(true, 'success', message);
          this.setState({
            showCongrats: true,
          });
          setTimeout(() => {
            history.push(`/psc-peer-feedback/${id}`);
          }, 3000);
        } else {
          this.handleNotification(true, 'error', error?.message);
        }
      })
      .catch((err) => {
        this.handleErrorNotification(err);
      });
  };

  render() {
    const { match: { params: { id } = {} } = {} } = this.props;
    const {
      notifShow,
      notifKind,
      notifMessage,
      showCongrats = false,
      sections,
      candidateInfo,
      referenceId,
    } = this.state;

    return (
      <div>
        {notifShow && (
          <InlineNotification
            lowContrast
            hideCloseButton
            className="m-0 mt-3 p-0 w-100"
            kind={notifKind}
            title={notifMessage}
            onCloseButtonClick={() => {
              this.setState({ notifShow: false });
            }}
          />
        )}

        <div className="mt-4">
          {showCongrats && (
            <div className="d-flex flex-column justify-content-center align-items-center sub_div_height overflow-hidden p-3 text-center ">
              <h2 className="cds--type-bold">Thank you!</h2>
              <div className="">Your responses have been submitted!</div>
            </div>
          )}

          {!showCongrats && !_.isEmpty(sections) && (
            <div className="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <div>PSC Peer Feedback Forms</div>
                <div className="d-flex align-items-center">
                  <div className="cds--type-zeta">Reference Id:</div>
                  <div className="ml-2">{referenceId}</div>
                </div>
              </div>
              <div>
                <Accordion className="css-gba-accr-z" align="start">
                  {_.map(_.entries(sections), ([sectionKey, section], idx) => {
                    const { sectionName, status, peerList } = section || {};
                    const statusColor = getStatusColor(lowerAndTrim(status));
                    return (
                      <AccordionItem
                        className=" mt-2"
                        title={
                          <div className="cds--row align-items-center justify-content-between">
                            <div className="ml-2 h6 font-weight-bold w-50">
                              {sectionName}
                            </div>
                            <div className="d-flex justify-content-center ml-3">
                              <strong
                                className={`text-capitalize cds--tag ${statusColor} xpa-name-overflow`}
                                title={status}>
                                {status}
                              </strong>
                            </div>
                          </div>
                        }>
                        <div>
                          {idx === 0 || idx === 1 ? (
                            <div>
                              <GenericDataTable
                                className=""
                                tbodyClass=""
                                otherActions={[]}
                                headers={[
                                  {
                                    key: 'header_0',
                                    header: 'Peer Feedback',
                                  },
                                  {
                                    key: 'header_1',
                                    header: 'Status',
                                  },
                                  {
                                    key: 'header_2',
                                    header: 'Action',
                                  },
                                ]}
                                rows={
                                  peerList &&
                                  Array.isArray(peerList) &&
                                  peerList.length > 0
                                    ? peerList.map((itm, idx) => {
                                        const {
                                          candidateId,
                                          fullName,
                                          status,
                                        } = itm || {};

                                        const statusColor = getStatusColor(
                                          lowerAndTrim(status)
                                        );

                                        return {
                                          id: `row_${idx + 1}_${candidateId}`,
                                          header_0: (
                                            <div className="cds--row align-items-center mt-2 mt-xl-0">
                                              <div className="cds--col-md-2 cds--col-sm-3 cds--col-xs-3 cds--type-zeta d-xl-none d-lg-none d-xl-none">
                                                Peer Feedback
                                              </div>
                                              <div className="cds--col-lg-12 cds--col-md-6 cds--col-sm-6 cds--col-xs-6">
                                                {fullName}
                                              </div>
                                            </div>
                                          ),
                                          header_1: (
                                            <div className="cds--row align-items-center mt-2 mt-xl-0">
                                              <div className="cds--col-md-2 cds--col-sm-3 cds--col-xs-3 cds--type-zeta d-xl-none d-lg-none d-xl-none">
                                                Status
                                              </div>
                                              <div className="cds--col-lg-12 cds--col-md-6 cds--col-sm-6 cds--col-xs-6">
                                                <strong
                                                  className={`text-capitalize cds--tag ${statusColor} xpa-name-overflow`}
                                                  title={status}>
                                                  {status}
                                                </strong>
                                              </div>
                                            </div>
                                          ),
                                          header_2: (
                                            <div className="cds--row align-items-center mt-2 mt-xl-0">
                                              <div className="cds--col-md-2 cds--col-sm-3 cds--col-xs-3 cds--type-zeta d-xl-none d-lg-none d-xl-none">
                                                Action
                                              </div>
                                              <div className="cds--col-lg-12 cds--col-md-6 cds--col-sm-6 cds--col-xs-6">
                                                <a
                                                  className="xpa-link"
                                                  href={`/psc-peer-feedback/${id}/assessment/${candidateId}/${sectionKey}`}>
                                                  {lowerAndTrim(status) ===
                                                  'draft'
                                                    ? 'Take Assessment'
                                                    : 'Edit Assessment'}
                                                </a>
                                              </div>
                                            </div>
                                          ),
                                        };
                                      })
                                    : []
                                }
                                selectable={false}
                                searchable={false}
                                onSearchInputChange={() => {}}
                                expandable={false}
                                batchActions={false}
                              />
                            </div>
                          ) : (
                            <a
                              className="xpa-link"
                              href={`/psc-peer-feedback/${id}/assessment/${candidateInfo?.candidateId}/${sectionKey}`}>
                              {lowerAndTrim(status) === 'draft'
                                ? 'Take Assessment'
                                : 'Edit Assessment'}
                            </a>
                          )}
                        </div>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          )}
        </div>
        {!showCongrats && (
          <div className="fixed-bottom justify-content-end">
            <div>
              <Button className="" onClick={() => this.handleFinalSubmit()}>
                Submit Assessment
              </Button>
            </div>
          </div>
        )}
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.PscPeerFeedback.loading,
  assessmentInfo: state.PscPeerFeedback.assessmentInfo,
});

const mapDispatchToProps = {
  getAsessmentInfoByUUID,
  submitEntireAssessment,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PscPeerFeedbackAssessList)
);
