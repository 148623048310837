import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, InlineNotification } from '@carbon/react';
import {
  getAsessmentInfoByUUID,
  submitRetakeRequest,
} from '../../../actions/PscPeerFeedback';
import JDProcessOverlay from '../../../components/common/JDProcessOverlay';
import CscImage from '../../../assets/images/x0pa-brown.png';
import { lowerAndTrim } from '../../../utils/helpers';
import _ from 'lodash';

class PscPeerFeedbackHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assessStatus: 'completed',
    };
  }

  componentDidMount() {
    this.handleGetAssessmentInfo();
  }

  handleNotification = (nshow, nkind, nmessage) => {
    this.setState({
      notifShow: nshow,
      notifKind: nkind,
      notifMessage: nmessage,
    });
    setTimeout(() => {
      this.setState({
        notifShow: false,
        notifKind: nkind,
        notifMessage: nmessage,
      });
    }, 5000);
  };

  handleErrorNotification = (err) => {
    console.error(err);
    window.scrollTo(0, 0);
    const { error, message } = err || {};
    let errorMessage = message;
    if (!errorMessage && error) {
      const { message } = error || {};
      errorMessage = message;
    }
    if (errorMessage) {
      errorMessage = `${errorMessage} Please try again. If issue persists then contact us at support@x0pa.com`;
    }
    const defaultMessage =
      'Unknown error occured. Please try again. If issue persists then contact us at support@x0pa.com';

    this.handleNotification(true, 'error', errorMessage || defaultMessage);
  };

  handleGetAssessmentInfo = () => {
    const { match: { params: { id } = {} } = {} } = this.props;
    this.props
      .getAsessmentInfoByUUID(id)
      .then((res) => {
        if (res && !res.error) {
          const { status, sections, candidateInfo, retakeStatus, surveyInfo } =
            res || {};
          const { candidateName } = candidateInfo || {};
          const { isSurveyEnded } = surveyInfo || {};
          this.setState({
            assessStatus: status,
            sections,
            candidateName,
            retakeStatus,
            isSurveyEnded,
          });
        } else {
          this.handleErrorNotification();
        }
      })
      .catch((err) => {
        console.error(err);
        this.handleErrorNotification(err);
      });
  };

  handleChange = (e) => {
    const { name, value } = (e && e.target) || {};
    this.setState({
      [name]: value,
    });
  };

  handleStart = () => {
    const { match: { params: { id } = {} } = {}, history } = this.props;
    history?.push(`/psc-peer-feedback/${id}/assessments`);
  };

  handleRetakeAssess = () => {
    const { match: { params: { id } = {} } = {} } = this.props;

    this.props
      .submitRetakeRequest({ publicId: id })
      .then((res) => {
        const { error, message } = res || {};
        if (res && !error) {
          this.handleNotification(true, 'success', message);
          this.handleGetAssessmentInfo();
        } else {
          this.handleNotification(true, 'error', error?.message);
        }
      })
      .catch((err) => {
        this.handleErrorNotification(err);
      });
  };

  render() {
    const { history } = this.props;
    const { push } = history || {};
    const {
      notifShow,
      notifKind,
      notifMessage,
      fullName = '',
      assessStatus,
      sections,
      candidateName,
      retakeStatus,
      isSurveyEnded,
    } = this.state;

    const isThisAssessCompletedByUser =
      lowerAndTrim(assessStatus) === 'completed';

    return (
      <div>
        {notifShow && (
          <InlineNotification
            lowContrast
            hideCloseButton
            className="mt-2 p-0 w-100"
            kind={notifKind}
            title={notifMessage}
            onCloseButtonClick={() => {
              this.setState({ notifShow: false });
            }}
          />
        )}

        <div className="cds--row css-web-height w-100 m-0">
          <div className="cds--col-lg-8 cds--col-md-16 cds--col-sm-16 cds--col-xs-16 left-bg-color d-flex flex-column justify-content-center align-items-center">
            <div>
              <img style={{ width: 200 }} src={CscImage} alt="CSC Image" />
            </div>
          </div>
          <div className="cds--col-lg-8 cds--col-md-16 cds--col-sm-16 cds--col-xs-16 d-flex flex-column justify-content-center align-items-center">
            {!this.props.loading && (
              <div className="text-center">
                <div className="mt-2 mb-3 font-weight-bold h6">
                  Hi{' '}
                  <span className="text-capitalize cds--type-bold">
                    {candidateName},
                  </span>
                </div>
                <h5 className="mb-2">
                  <u>
                    Please read these instructions carefully before completing
                    the questionnaire.
                  </u>
                </h5>

                {isSurveyEnded ? (
                  <>
                    {retakeStatus == null ? (
                      <>
                        <div className="h3 font-weight-bold">
                          Survey has been ended. If you would like to retake,
                          you can request for a retake by clicking on the button
                          below
                        </div>
                        <div className="mt-3 cds--row align-items-center justify-content-center">
                          <Button
                            className=""
                            onClick={() => this.handleRetakeAssess()}
                            kind="primary">
                            Retake Assessment
                          </Button>
                        </div>
                      </>
                    ) : lowerAndTrim(retakeStatus) === 'requested' ? (
                      <div className="h3 font-weight-bold">
                        Your retake request has been successfully submitted. We
                        will update you once it is approved.{' '}
                      </div>
                    ) : lowerAndTrim(retakeStatus) === 'approved' ? (
                      <>
                        <div className="h3 font-weight-bold">
                          Your retake request has been approved.
                        </div>
                        <div className="mt-3 cds--row align-items-center justify-content-center">
                          <Button
                            className=""
                            onClick={() => this.handleStart()}
                            kind="primary">
                            Start
                          </Button>
                        </div>
                      </>
                    ) : lowerAndTrim(retakeStatus) === 'rejected' ? (
                      <div className="h3 font-weight-bold">
                        Your retake request has been rejected. Unfortunately,
                        you cannot retake the assessment.
                      </div>
                    ) : lowerAndTrim(retakeStatus) === 'completed' ? (
                      <div className="h3 font-weight-bold">
                        You have already utilized your one retake opportunity.
                        Unfortunately, you cannot request further.
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <div className="mt-3 cds--row align-items-center justify-content-center">
                    <Button
                      className=""
                      onClick={() => this.handleStart()}
                      kind="primary">
                      {lowerAndTrim(assessStatus) === 'in progress'
                        ? 'Continue'
                        : lowerAndTrim(assessStatus) === 'completed'
                        ? 'Edit Responses'
                        : 'Start'}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.PscPeerFeedback.loading,
});

const mapDispatchToProps = {
  getAsessmentInfoByUUID,
  submitRetakeRequest,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PscPeerFeedbackHomePage)
);
