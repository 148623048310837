import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  TextInput,
  Button,
  InlineNotification,
  TextArea,
  RadioButton,
  RadioButtonGroup,
  ListItem,
} from '@carbon/react';
import {
  getFeedbackAssessInfoById,
  submitFeedbackAssessResponse,
} from '../../../actions/Assessments';
import PublicHeader from '../../../headers/PublicHeader';
import CscImage from '../../../assets/images/x0pa-brown.png';
import './CandidateSoftskillAssessment.css';

const serverBusyMessage =
  'Unable to process the request, please try again. If the issue persists, please contact cscollege_ars@cscollege.gov.sg';

class CandidateFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCongrats: false,
      startFeedback: false,
    };
  }

  componentDidMount() {
    this.handleGetFeedbackInfo();
  }

  handleNotification = (nshow, nkind, nmessage) => {
    this.setState({
      notifShow: nshow,
      notifKind: nkind,
      notifMessage: nmessage,
    });
    setTimeout(() => {
      this.setState({
        notifShow: false,
        notifKind: nkind,
        notifMessage: nmessage,
      });
    }, 5000);
  };

  handleErrorNotification = (err) => {
    console.error(err);
    window.scrollTo(0, 0);
    const { error, message } = err || {};
    let errorMessage = message;
    if (!errorMessage && error) {
      const { message } = error || {};
      errorMessage = message;
    }
    if (errorMessage) {
      errorMessage = `${errorMessage} Please try again. If issue persists then contact us at cscollege_ars@cscollege.gov.sg`;
    }
    const defaultMessage =
      'Unknown error occured. Please try again. If issue persists then contact us at cscollege_ars@cscollege.gov.sg';

    this.handleNotification(true, 'error', errorMessage || defaultMessage);
  };

  handleGetFeedbackInfo = () => {
    const { match: { params: { id } = {} } = {} } = this.props;
    this.props
      .getFeedbackAssessInfoById(id)
      .then((res) => {
        if (res && !res.error) {
          const { data } = res || {};
          const {
            assessmentFeedbackId,
            status,
            scorecardTemplateConfiguration,
            Candidate,
            Scenariocandidatemap,
            feedbackType,
          } = data || {};
          const { scenarioId } = Scenariocandidatemap || {};

          this.setState({
            assessmentFeedbackId,
            status,
            scorecardTemplateConfiguration,
            Candidate,
            scenarioId,
            feedbackType,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        this.handleErrorNotification(err);
      });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeRadioButton = (value, ratingCriteriaId, idx) => {
    this.setState({
      [`answer-${ratingCriteriaId ? ratingCriteriaId : ''}`]: value,
    });
  };

  RadioSelectedOptions = (opt, id, optionId) => {
    return (
      <RadioButton
        className="m-2"
        key={`${opt}-${id}`}
        id={`${opt}-${id}`}
        labelText={opt}
        value={optionId}
      />
    );
  };

  handleValiddateFeedbackForm = (configuration) => {
    let finalAnswers = [];
    const currState = {};
    if (
      configuration &&
      Array.isArray(configuration) &&
      configuration.length > 0
    ) {
      configuration.forEach((group) => {
        const { evalCriteria = [], groupName = '' } = group;
        let tempAnswers = [];

        if (
          evalCriteria &&
          Array.isArray(evalCriteria) &&
          evalCriteria.length > 0
        ) {
          tempAnswers = evalCriteria.map((res, idx) => {
            const {
              ratingCriteriaId,
              ratingCriteriaName,
              ratingCriteriaType,
              ratingCriteriaValidation,
            } = res;
            if (
              ratingCriteriaValidation &&
              (!this.state[`answer-${ratingCriteriaId}`] ||
                this.state[`answer-${ratingCriteriaId}`].length <= 0)
            ) {
              currState[`valid-${ratingCriteriaId}`] = true;
              currState.errorValid = true;
            } else {
              currState[`valid-${ratingCriteriaId}`] = false;
              currState.errorValid = false;
              let answer = '';
              let ratingCriteriaTypeId = 0;
              if (ratingCriteriaType === 'text') {
                ratingCriteriaTypeId = 1;
              } else if (ratingCriteriaType === 'rating') {
                ratingCriteriaTypeId = 2;
              } else if (ratingCriteriaType === 'number') {
                ratingCriteriaTypeId = 3;
              }
              answer = this.state[`answer-${ratingCriteriaId}`];
              const data = {
                ratingCriteriaTypeId,
                ratingCriteriaId,
                ratingCriteriaName,
                textAnswer: answer,
                groupName,
              };

              return data;
            }
            return null;
          });

          finalAnswers = [...finalAnswers, tempAnswers];
        }
      });
    }
    return { finalAnswers, currState };
  };

  handleSubmitFeedbackForm = (configuration) => {
    const finalData = this.handleValiddateFeedbackForm(configuration);
    const { finalAnswers, currState } = finalData || {};
    this.setState(
      {
        ...currState,
      },
      () => {
        let containsNull = true;
        for (let i = 0; i < finalAnswers.length; i++) {
          const res = finalAnswers[i];
          containsNull = res.some((el) => {
            return el == null;
          });
          if (containsNull) break;
        }
        if (!containsNull && this.state.errorValid === false) {
          const scores = finalAnswers.map((groups) => {
            const evalCriteria = groups.map((answer) => {
              const {
                ratingCriteriaTypeId,
                ratingCriteriaId,
                ratingCriteriaName,
                textAnswer,
                groupName,
              } = answer;

              return {
                ratingCriteriaId,
                starVal: ratingCriteriaTypeId == 2 ? textAnswer : null,
                textVal: ratingCriteriaTypeId == 1 ? textAnswer : null,
                numberVal: ratingCriteriaTypeId == 3 ? textAnswer : null,
                groupName,
                ratingCriteria: {
                  ratingCriteriaId,
                  ratingCriteriaName,
                  ratingCriteriaTypeId,
                  ratingCriteriaType: {
                    ratingCriteriTypeId: ratingCriteriaTypeId,
                    ratingCriteriaTypeName:
                      ratingCriteriaTypeId == 1
                        ? 'text'
                        : ratingCriteriaTypeId == 2
                        ? 'rating'
                        : 'number',
                  },
                },
              };
            });
            const data = { groupName: groups[0].groupName, evalCriteria };
            return data;
          });
          const { assessmentFeedbackId } = this.state;

          const finalData = {
            assessmentFeedbackId,
            responses: scores,
          };

          this.props
            .submitFeedbackAssessResponse(finalData)
            .then((res) => {
              const { error, isSuccess } = res || {};
              if (error) {
                throw error;
              }
              if (!res) {
                throw new Error(serverBusyMessage);
              }
              if (!error && isSuccess) {
                console.log('isSuccess ', isSuccess);
                this.setState({ showCongrats: true });
              }
            })
            .catch((err) => {
              this.handleErrorNotification(err);
            });
        }
      }
    );
  };

  render() {
    const { history } = this.props;
    const { push } = history || {};
    const {
      scorecardTemplateConfiguration: configuration,
      notifShow,
      notifKind,
      notifMessage,
      showCongrats,
      status: feedbackAssessStatus,
      startFeedback,
      Candidate,
      scenarioId,
      feedbackType,
    } = this.state;
    const { firstName = '', lastName = '', fullName = '' } = Candidate || {};
    return (
      <div>
        {notifShow && (
          <InlineNotification
            lowContrast
            hideCloseButton
            className="m-0 p-0 w-100"
            kind={notifKind}
            title={notifMessage}
            onCloseButtonClick={() => {
              this.setState({ notifShow: false });
            }}
          />
        )}

        {feedbackAssessStatus === 'Completed' ? (
          <div className="cds--row css-web-height w-100 m-0">
            <div className="cds--col-lg-8 cds--col-md-16 cds--col-sm-16 cds--col-xs-16 left-bg-color d-flex flex-column justify-content-center align-items-center">
              <div>
                <img style={{ width: 200 }} src={CscImage} alt="CSC Image" />
              </div>
            </div>
            <div className="cds--col-lg-8 cds--col-md-16 cds--col-sm-16 cds--col-xs-16 d-flex flex-column justify-content-center align-items-center">
              <div className="">
                <div className="h3 font-weight-bold">
                  Thank you! You've already submitted the feedback.
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {!startFeedback ? (
              <div className="cds--row css-web-height w-100 m-0">
                <div className="cds--col-lg-8 cds--col-md-16 cds--col-sm-16 cds--col-xs-16 left-bg-color d-flex flex-column justify-content-center align-items-center">
                  <div>
                    <img
                      style={{ width: 200 }}
                      src={CscImage}
                      alt="CSC Image"
                    />
                  </div>
                </div>
                <div className="cds--col-lg-8 cds--col-md-16 cds--col-sm-16 cds--col-xs-16 d-flex flex-column justify-content-center align-items-center">
                  <div className="">
                    <div className="mt-2 font-weight-bold h6">
                      Hi{' '}
                      <span className="text-capitalize cds--type-bold">
                        {fullName}
                      </span>
                    </div>
                    {feedbackType === 'psc-application-process' &&
                      scenarioId == 4 && (
                        <div>
                          <h5 className="my-2">
                            <u>Instructions</u>
                          </h5>
                          <div className="mt-2">
                            Thank you for submitting your PSC Scholarship
                            application.
                          </div>
                          <div className="mt-2">
                            We would appreciate if you would complete this short
                            questionnaire to help us understand your experience
                            during the PSC Scholarship application process.
                          </div>
                          <div className="mt-2">
                            All individual responses remain confidential. Data
                            will only be presented back to PSC at the aggregate
                            level.
                          </div>
                        </div>
                      )}
                    <hr />
                    <div className="mt-2 text-center">
                      <Button
                        onClick={() => this.setState({ startFeedback: true })}
                        kind="primary"
                        disabled={
                          feedbackAssessStatus === 'Completed' ||
                          configuration == null
                        }>
                        Continue
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="m-4 p-4">
                {showCongrats && (
                  <div className="d-flex flex-column justify-content-center align-items-center sub_div_height overflow-hidden text-center xvi-completion-page">
                    <h2 className="font-weight-bold">Thank you!</h2>
                    <div className="h5">
                      Your responses have been submitted!
                    </div>
                  </div>
                )}
                {!showCongrats && (
                  <>
                    {configuration &&
                      Array.isArray(configuration) &&
                      configuration.length > 0 && (
                        <div className="feedback-table">
                          {configuration.map((group) => {
                            const { groupName, evalCriteria } = group;
                            return (
                              <div className="mt-3">
                                <h4 className="">{groupName}</h4>
                                {evalCriteria &&
                                  Array.isArray(evalCriteria) &&
                                  evalCriteria.length > 0 &&
                                  evalCriteria.map((evalCriteria, idx) => {
                                    const {
                                      ratingCriteriaId = '',
                                      ratingCriteriaName = '',
                                      ratingCriteriaType,
                                      ratingCriteriaValidation,
                                      questionConfig,
                                    } = evalCriteria || {};
                                    return (
                                      <Fragment>
                                        <div className="feedback-row cds--row align-items-center mt-2">
                                          <div className="cds--col-lg-8">
                                            {ratingCriteriaValidation && (
                                              <span className="text-danger">
                                                *
                                              </span>
                                            )}{' '}
                                            {ratingCriteriaName}
                                          </div>
                                          <div className="cds--col-lg-8">
                                            {ratingCriteriaType === 'text' && (
                                              <TextArea
                                                className="mt-2 mb-1"
                                                key={ratingCriteriaId}
                                                name={`answer-${ratingCriteriaId}`}
                                                labelText=""
                                                hideLabel
                                                onChange={(e) =>
                                                  this.handleChange(e)
                                                }
                                                placeholder="Type Here"
                                                invalid={
                                                  this.state[
                                                    `valid-${ratingCriteriaId}`
                                                  ]
                                                }
                                                invalidText="Please enter valid answer"
                                                id={`answer-${ratingCriteriaId}`}
                                                value={
                                                  this.state[
                                                    `answer-${ratingCriteriaId}`
                                                  ]
                                                }
                                              />
                                            )}
                                            {ratingCriteriaType ===
                                              'number' && (
                                              <TextInput
                                                type="number"
                                                className="mt-2 mb-1"
                                                key={ratingCriteriaId}
                                                name={`answer-${ratingCriteriaId}`}
                                                labelText=""
                                                hideLabel
                                                onChange={(e) =>
                                                  this.handleChange(e)
                                                }
                                                invalid={
                                                  this.state[
                                                    `valid-${ratingCriteriaId}`
                                                  ]
                                                }
                                                invalidText="Please enter valid answer"
                                                id={`answer-${ratingCriteriaId}`}
                                                value={
                                                  this.state[
                                                    `answer-${ratingCriteriaId}`
                                                  ]
                                                }
                                              />
                                            )}
                                            {ratingCriteriaType === 'rating' &&
                                              questionConfig && (
                                                <>
                                                  <RadioButtonGroup
                                                    onChange={(e) =>
                                                      this.handleChangeRadioButton(
                                                        e,
                                                        ratingCriteriaId,
                                                        idx
                                                      )
                                                    }
                                                    valueSelected={
                                                      this.state[
                                                        `answer-${
                                                          idx + 1
                                                        }-${ratingCriteriaId}`
                                                      ]
                                                    }
                                                    labelPosition="right"
                                                    legend=""
                                                    id={`answer-${
                                                      idx + 1
                                                    }-${ratingCriteriaId}`}
                                                    name={`answer-${
                                                      idx + 1
                                                    }-${ratingCriteriaId}`}
                                                    orientation="horizontal">
                                                    {questionConfig?.options?.map(
                                                      (option) => {
                                                        return this.RadioSelectedOptions(
                                                          option.optionName,
                                                          ratingCriteriaId,
                                                          option.optionId
                                                        );
                                                      }
                                                    )}
                                                  </RadioButtonGroup>
                                                  {this.state[
                                                    `valid-${ratingCriteriaId}`
                                                  ] === true && (
                                                    <div className="cds--form-requirement">
                                                      Please select valid
                                                      option.
                                                    </div>
                                                  )}
                                                </>
                                              )}
                                          </div>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                              </div>
                            );
                          })}
                          <div className=" cds--row ">
                            <div className="mr-3 col-16 pt-2 ml-1">
                              <Button
                                small
                                kind="primary"
                                onClick={() =>
                                  this.handleSubmitFeedbackForm(configuration)
                                }
                                className="">
                                Submit
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.Assessments.loading,
  assessCandInfo: state.Assessments.assessCandInfo,
});

const mapDispatchToProps = {
  getFeedbackAssessInfoById,
  submitFeedbackAssessResponse,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CandidateFeedback)
);
